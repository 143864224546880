export const HOME_PATH = "";
export const LOGIN_PATH = "/login";
export const USERS_PATH = "/users";
export const AGENCY_PATH = "/agencies";
export const NOT_ALLOWED = "/not-allowed";
export const CHANGE_PASSWORD_PATH = "/change-password";
export const VERIFICATION_PATH = "/verification";
export const DASHBOARD_PATH = "/dashboard";
export const SUBMISSION_PATH = "/visits";
export const FORCE_RESET_PASSWORD_PATH = "/force-reset-password";
export const USER_LISTING_PATH = "/users";
export const IOH_USER_PATH = "/ioh-users";
export const VISIT_PATH = "visits";
export const EDOCS = "/e-docs";
export const EPISODE_PATH = "/episodes";
export const PVISITS = "/p-visits";
export const SUPPORT_TICKET = "/support-ticket";
export const UNMATCHED_VISITS = "/unmatched-visits";
export const TOKEN_KEY = "_token";
export const NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED";
export const NEW_PASSWORD_REQUEST_SEND = "NEW_PASSWORD_REQUEST_SEND";
export const USER_VERIFIED = "USER_VERIFIED";
export const USER_PROFILE = "Profile";
export const CREATE_NEW_PASSWORD = "/create-new-password";
export const FORGOT_PASSWORD = "/forgot-password";
export const PASSWORD_RESET_DONE = "/password-reset-done";
export const SUPER_ADMIN = "Super Admin";
export const IOH_ADMIN = "IOH Admin";
export const COMPANY_ADMIN = "Company Admin";
export const dateFormat = "MM-dd-yyyy";
export const dateFormatStartWithYear = "yyyy-MM-dd";
export const dateFormatStartWithYearWithOutDashes = "yyyyMMdd";
export const DATE_PICKER_FORMAT = "mm-dd-yy";
export const DATE_PICKER_PLACEHOLDER = "mm-dd-yy - mm-dd-yy";
export const dateAndTimeFormat = "MM-dd-yyyy hh:mm a";
export const LOGO_TEXT = "Real-time chart validation for home health agencies.";
export const EXPIRED_PASSWORD_TEXT = "Security alert: You have not changed your password since 60 days.";
export const FOOTER_TEXT = "© " + new Date().getFullYear() + " io Health Tech, All Rights Reserved.";
export const REFRESH_TOKEN_INTERVAL = 5 * 60 * 1000 - 5000;
export const VERIFICATION_CODE_EXPIRED_MESSAGE = "Your Verification Code has been Expired.";
export const PHONE_NUMBER_LENGTH = 10;
export const PHONE_NUMBER_REGEX = "^\\d{10}$";
export const DOB_DATE_FORMAT = "mm/dd/yy";
export const ePH_LINK_SEND = "The ePH Link request has been sent successfully";
export const DOB_NOT_MATCH = "The DOB  provided does not match. Please try again.";
export const DOB_MATCH = "The DOB provided has been matched successfully.";
export const FILE_DOWNLOAD_SUCCESS = "File downloaded successfully.";
export const US_TIME_ZONE = "locale";
export const REPORT_TIME_ZONE = "America/New_York";
export const IMPACT = "impact";
export const SEARCH_PATIENT_BY_NAME_OR_MRN = "Search by first/last name or mrn";
export const SEARCH_PATIENT_BY_ID = "Search by Id";
export const SEARCH_PATIENT_BY_CASE_MANAGER = "Search by case manager";
export const SEARCH_PATIENT_BY_CASE_CLINICIAN = "Search by clinician";
export const SEARCH_PATIENT_BY_MRN = "Search by first/last name or mrn";
export const SEARCH_UNMATCHED_VISIT_BY_ID = "Search unmatched visit by ID";
export const TEMP_PASSWORD_EXPIRED = "Password reset Email has been sent.";
export const DEFAULT_USER_IMAGE = "assets/images/Subtract.svg";
export const USER_NOT_ACTIVE = "Your account has been deactivated,\n To reactivate it, please contact your account administrator.";
export const USER_DELETED = "Your account has been deleted.";
export const NO_USER_FOUND = "User not authorized";
export const NO_AUTHORIZE_USER = "User not authorized!";
export const BULK_USER_UPLOAD = "Bulk Users Uploaded successfully";
export const BULK_USER_UPLOAD_FAILED = "Bulk Users Upload failed";
export const BULK_USER_UPLOAD_FAILED_MESSAGE = "Please verify the agency name and try again";
export const BULK_USER_UPLOAD_ALLOWED_FORMAT = "text/csv";
export const BULK_USER_UPLOAD_FILE_FORMAT_ERROR = "The file format should be csv";
export const BULK_USER_UPLOAD_FILE_SIZE = "File size should be less than 5MB";
export const USER_NOT_FOUND = "Incorrect username or password.";
export const EMAIL_SENT_IF_USER_EXISTS = "If the user exists, an email has been sent to the email provided";
export const USER_ACCOUNT_ALREADY_EXIST = "An account with the given email already exists.";
export const DEFAULT_MRN_LENGTH = 16;
export const DEFAULT_UNMATCHED_MRN_LENGTH = 16;
export const DEFAULT_PATIENT_ID_LENGTH = 20;
export const DEFAULT_PATIENT_NAME_LENGTH = 17;
export const DEFAULT_CASE_MANAGER_NAME_LENGTH = 20;
export const DEFAULT_ROLE_NAME_LENGTH = 23;
export const DEFAULT_SEARCH_MIN_CHARACTER = 2;
export const DEFAULT_AGENCY_EDOC_LINK_EXPIRATION_TIME = 28;
export const DEFAULT_ORDER_BY_PATIENT_VISIT = "submissionDate";
export const DEFAULT_ORDER_PATIENT_VISIT = "-1";
export const PATH_BEFORE_REFRESH = "pathBeforeRefresh";
export const CURRENT_RELEASE_VERSION = "V24.11.2";
export const RELOAD_PAGE_AFTER_RELEASE = 5000;
export const DEBOUNCE_TIME_SET = 2000;
export const USER_ACTIVITY_HOURS_IN_PAST = 72;
export const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
export const EXCEL_EXTENSION = ".xlsx";
export const AGENCY_REPORT_FILE_NAME = "AgencyReport-";
export const USER_DEVICES_REPORT_FILE_NAME = "Devices";
export const USER_STATUS_REPORT_FILE_NAME = "User Status-";
export const USER_STATUS_REPORT_FILE_TITLE = "User Status";
export const ERROR_WARNING_REPORT_FILE_NAME = "Errors and Warnings Report";
export const EDOC_REPORT = "eDoc";
export const USER_STATUS_FILE_NAME = "User Status";
export const UTILIZATION_REPORT_FILE_NAME = "Utilization";
export const PASSWORD_STRENGTH_VALIDATION_MESSAGE = "Min 8 characters with a combination of letters,numbers and one special character.";
export const PASSWORD_STRENGTH_STRONG = "Strong";
export const PASSWORD_STRENGTH_STRONG_CLASS = "strong-password";
export const PASSWORD_STRENGTH_MEDIUM = "Medium";
export const PASSWORD_STRENGTH_MEDIUM_CLASS = "medium-password";
export const PASSWORD_STRENGTH_WEAK = "Weak";
export const PASSWORD_STRENGTH_WEAK_CLASS = "weak-password";
export const INVALID_PASSWORD = "Invalid";
export const INVALID_PASSWORD_CLASS = "invalid-password";

export const DEFAULT_VISIT_DATE_FILTER = 60;
export const DEFAULT_PATIENT_DATE_FILTER = 60;
export const DEFAULT_EPISODE_DATE_FILTER = 60;

export const CHANGE_USER_PASSWORD_DAYS = 60;
export const CHANGE_USER_PASSWORD_BANNER_SHOW_DAYS = 53;

export const PASSWORDS_MUST_MATCH = "Password and confirm password must be same.";

export const RESET_PASSWORD_FIELDS_MISSING = "Please fill out all fields to set your password.";
export const RESET_PASSWORDS_NOT_MATCHING = "Passwords do not match! Please click the eye icon to double check.";
export const RESET_PASSWORDS_NOT_LONG_ENOUGH = "The password you created is too easy, please create a more complex password to keep data safe!";
export const EMAIL_ALREADY_REGISTERED_USER = "The email address you have provided is already registered with another User.";
export const EMAIL_ALREADY_REGISTERED_COMPANY = "The email address you have provided is already registered with another Company.";
export const EMAIL_ALREADY_REGISTERED_AGENCY = "The email address you have provided is already registered with another Agency.";
export const FORBIDDEN = "Forbidden: ";
export const FORBIDDEN_MESSAGE = "Sorry, You are not allowed to access this resource.";
export const FILE_NOT_OPENED = "Not Opened";

export const ANONYMOUS = "anonymous";
export const QUESTIONAIR_TYPE_WARN = "Warn";
export const QUESTIONAIR_TYPE_WARNING = "Warning";
export const QUESTIONAIR_TYPE_ERROR = "Error";
export const CLINICIAN_CHANGE_REPORT_NAME = "Clinician Change";
export const CLINICIAN_SUGGESTION_CALCULATION_REPORT_NAME = "The suggestion calculator report";
export const REGISTRATION_ACTIVATED_STATUS = "Activated";
export const REGISTRATION_WAITING_STATUS = "Waiting";
export const ACTIVATION_EMAIL_SUCCESS_MESSAGE = "Activation email sent successfully";

export const NO_OF_RECORDS_PER_PAGE = 30;

export enum FileType {
  file = "file",
  piReportFile = "piReportFile",
  otherFile = "otherFile",
}

export enum FileTypeNames {
  file = "ePH Report",
  piReportFile = "PIReport",
  otherFile = "Other Report",
}

export enum FileTypeHistory {
  ePH = "ePH File",
  piReport = "PIReport File",
  other = "Other File",
}

export enum NewFileUploadType {
  file = "ePH File",
  piReportFile = "PIReport File",
  otherFile = "Other File",
}

export enum DeletedFileType {
  file = "ePH",
  piReportFile = "piReport",
  otherFile = "other",
}

export enum RoleID {
  Admin = "SUPER_ADMIN",
  iohAdmin = "IOH_ADMIN",
  companyAdmin = "COMPANY_ADMIN",
  AgencyAdmin = "AGENCY_ADMINISTRATOR",
  IOHAgencyAdmin = "IOH_AGENCY_ADMINISTRATOR",
  CaseManager = "CASE_MANAGER",
  PatientManager = "PATIENT_MANAGER",
  Clinician = "CLINICIAN",
  OfficeWorker = "OFFICE_WORKER",
}

export const RoleNames = {
  SUPER_ADMIN: "Super Admin",
  IOH_ADMIN: "IOH Admin",
  COMPANY_ADMIN: "Company Admin",
  AGENCY_ADMINISTRATOR: "Agency Admin",
  IOH_AGENCY_ADMINISTRATOR: "IOH Agency Admin",
  CASE_MANAGER: "Case Manager",
  CLINICIAN: "Clinician",
  OFFICE_WORKER: "Office Worker",
};

export const MfaAllowedRoles = [
  RoleID.Admin.toString(),
  RoleID.iohAdmin.toString(),
  RoleID.companyAdmin.toString(),
  RoleID.AgencyAdmin.toString(),
  RoleID.IOHAgencyAdmin.toString(),
  RoleID.CaseManager.toString(),
];
export enum HideMrnForUsers {
  SUPER_ADMIN = "SUPER_ADMIN",
  IOH_ADMIN = "IOH_ADMIN",
  COMPANY_ADMIN = "COMPANY_ADMIN",
  IOH_AGENCY_ADMINISTRATOR = "IOH_AGENCY_ADMINISTRATOR",
}
export const AllExceptPatientAllowedRoles = [
  RoleID.Admin.toString(),
  RoleID.iohAdmin.toString(),
  RoleID.companyAdmin.toString(),
  RoleID.AgencyAdmin.toString(),
  RoleID.CaseManager.toString(),
  RoleID.OfficeWorker.toString(),
  RoleID.PatientManager.toString(),
  RoleID.Clinician.toString(),
];
export const adminRoles = [
  RoleID.Admin.toString(),
  RoleID.iohAdmin.toString(),
  RoleID.IOHAgencyAdmin.toString(),
  //RoleID.companyAdmin.toString(),
];
export const superAdminRoles = [
  RoleID.Admin.toString(),
  RoleID.iohAdmin.toString(),
  RoleID.IOHAgencyAdmin.toString(),
  RoleID.companyAdmin.toString(),
];
export const OnlyAdminAllowedRoles = [
  RoleID.Admin.toString(),
  RoleID.iohAdmin.toString(),
  RoleID.AgencyAdmin.toString(),
  RoleID.IOHAgencyAdmin.toString(),
  RoleID.companyAdmin.toString(),
];
export const OnlySuperAdminAllowedRoles = [
  RoleID.Admin.toString(),
  RoleID.iohAdmin.toString(),
  //RoleID.companyAdmin.toString()
];
export const OnlyAdminAndCompanyAdminAllowedRoles = [RoleID.Admin.toString(), RoleID.iohAdmin.toString(), RoleID.companyAdmin.toString()];
export const OnlyAllowedExceptClinicianRoles = [
  RoleID.Admin.toString(),
  RoleID.iohAdmin.toString(),
  RoleID.companyAdmin.toString(),
  RoleID.AgencyAdmin.toString(),
  RoleID.CaseManager.toString(),
  RoleID.OfficeWorker.toString(),
];
export const OnlyClinicianAllowedRoles = [
  RoleID.Admin.toString(),
  RoleID.iohAdmin.toString(),
  RoleID.companyAdmin.toString(),
  RoleID.AgencyAdmin.toString(),
  RoleID.CaseManager.toString(),
  RoleID.OfficeWorker.toString(),
  RoleID.Clinician.toString(),
];

export const appSettings = {
  showOK: true,
  showWarnError: false,
  highlightCircle: true,
  highlightSrect: false,
  highlightLrect: false,
  toastPopUp: false,
  crossRulesEnabled: false,
  crossRulesA2VsA1Enabled: false,
  dcVsSocRulesEnabled: false,
  webCrossRulesEnabled: false,
  webCrossRulesA2VsA1Enabled: false,
  webDcVsSocRulesEnabled: false,
  minBuildVersion: false,
  batteryOpPermission: false,
  readNotify: true
};

export const appSettingsCheckbox = [
  { label: "Minimum build version", value: "minBuildVersion" },
  { label: "Show Warning and Error", value: "showWarnError" },
  { label: "Show OK", value: "showOK" },
  { label: "Highlight Small Rectangle", value: "highlightSrect" },
  { label: "Highlight Circle", value: "highlightCircle" },
  { label: "Highlight Large Rectangle", value: "highlightLrect" },
  { label: "Cross Matrix Rules", value: "crossRulesEnabled" },
  { label: "Show Toast Popup", value: "toastPopUp" },
  { label: "DC vs SOC Rules (including A3 vs A1)", value: "dcVsSocRulesEnabled" },
  { label: "Web GG 1 & 2 Rules", value: "webCrossRulesA2VsA1Enabled" },
  { label: "GG 1 & 2 Rules", value: "crossRulesA2VsA1Enabled" },
  { label: "Web Cross Matrix Rules", value: "webCrossRulesEnabled" },
  { label: "Web DC vs SOC Rules (including A3 vs A1)", value: "webDcVsSocRulesEnabled" },
  { label: "Battery Op Permission", value: "batteryOpPermission" },
  { label: "Read Notify", value: "readNotify" }
];

export const allowToUpdateVisitsRoles = [RoleID.companyAdmin.toString(), RoleID.AgencyAdmin.toString(), RoleID.CaseManager.toString()];

export const OnlyCaseManagerClinicianAllowedRoles = [RoleID.CaseManager.toString(), RoleID.OfficeWorker.toString(), RoleID.Clinician.toString()];
export const portalFeatureValues = [{ name: "eDoc", value: false }];
export const rowsPerPageOptions = [10, 20, 30];
export const SPINNER_ICON = "https://icon-library.com/images/spinner-icon-gif/spinner-icon-gif-24.jpg";

// This group mapping will be change or get expanded over-time
export const ERROR_WARNING_DIAGNOSTIC_GROUP_MAPPING = [
  {
    group_values: ["Joint Replacement - Knee or Hip", "MZ41.1", "ortho-knee hip"],
    group_name: "MS-Rehab - Joint Replacement",
  },
  {
    group_values: ["S42.291D", "S83.232D", "M75.102", "M75.11", "Other orthopedic rehab"],
    group_name: "MS-Rehab - Other",
  },
  {
    group_values: ["ortho-spine"],
    group_name: "MS-Rehab - Spine",
  },
  {
    group_values: ["Cardiac"],
    group_name: "MMTA - Cardiac",
  },
  {
    group_values: ["Other", "default"],
    group_name: "Other",
  },
  {
    group_values: ["Wound Care"],
    group_name: "Wound",
  },
  {
    group_values: [],
    group_name: "NEURO_REHAB",
  },
  {
    group_values: [],
    group_name: "Complex",
  },
];

export const ANSWER_MAPPING = {
  "-1": "Not Assessed / No Information",
  "-2": "Unknown",
  "-3": "Not Applicable",
};
